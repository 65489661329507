'use strict';

const coord = {
  lat: 22.551224,
  lng: 113.930539
}
const ACCESS_TOKEN = 'pk.eyJ1IjoiZW5kb3BoYXNpZSIsImEiOiJja2J2MDlhemwwMWR5MzBwY2E1eG52Y2U4In0.5BXpTESi5GU53_MSVv0n6Q';

const ESC = 'Escape';

const $bodyEl = document.body;
const $burgerBtn = $bodyEl.querySelector('.js-burger-btn');
const $pageNavBtn = $bodyEl.querySelector('.page-nav');
const $pageBlocks = $bodyEl.querySelectorAll('[data-section]');
const $expandTextBtn = $bodyEl.querySelectorAll('.js-expand-btn');
const $viewerLoader = $bodyEl.querySelector('.pdt-viewer__loader');
const $contactsMap = $bodyEl.querySelector('#contactsMap');
const $preorderForm = $bodyEl.querySelector('.preorder__form');
const $contactsForm = $bodyEl.querySelector('.contacts-form');
const $dealerForm = $bodyEl.querySelector('.dealer-form');
const $expandSlideBtn = $bodyEl.querySelectorAll('.js-expand-slide-btn');
const $expandSlideClose = $bodyEl.querySelectorAll('.js-expand-slide-close');
const $fullsizeImg = $bodyEl.querySelectorAll('.js-show-fullsize');
const $videoPopupBtn = $bodyEl.querySelectorAll('.js-open-video');
const $popup = $bodyEl.querySelector('.popup');
const $popupWrapper = $bodyEl.querySelector('.js-popup-wrapper');
const $gallery = $bodyEl.querySelectorAll('.js-gallery');
const $header = $bodyEl.querySelector('.header');
const $menuBtnDisplayFS = $bodyEl.querySelector('#btn-fs');
const $fsMenu = document.querySelector('#full-screen-dropdown');
const $btnSwitchGalleryExt = document.querySelector('#btn-gallery-exterior');
const $btnSwitchGalleryInt = document.querySelector('#btn-gallery-interior');

const HEADER_HEIGHT = $header.offsetHeight;

// requestAnimationFrame
const raf = 
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function (callback) { window.setTimeout( callback, 1000 / 60 ) };

const isViewportChecker = (el) => {
  const elHeight = el.offsetHeight;
  const bounding = {
    top: el.getBoundingClientRect().top - HEADER_HEIGHT,
    bottom: el.getBoundingClientRect().bottom + elHeight / 3
  }

  return bounding.top >= -elHeight && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + elHeight;
};

document.addEventListener('DOMContentLoaded', function () {
  const element = document.querySelector('#full-screen-dropdown');
  document.body.appendChild(element);
});

const onMenuBtnClick = (e) => {
  const $navEl = e.target.parentNode;
  const element = document.querySelector('#full-screen-dropdown');
  const btnfs = document.querySelector('#btn-fs');

  if ($navEl.classList.contains('is-open')) {
    $navEl.classList.remove('is-open');
    $bodyEl.classList.remove('hidden');
    document.removeEventListener('keydown', onMenuEscPressed);
    $bodyEl.appendChild(element);
  } else {
    btnfs.appendChild(element);
    $navEl.classList.add('is-open');
    $bodyEl.classList.add('hidden');

  }
};

const onNavClick = (e) => {
  activateNavLink(e.target);
};

const onMenuEscPressed = (e) => {
  if (e.key === ESC) {
    e.preventDefault();
    onMenuBtnClick(e);
  }
}

const activateNavLink = (el) => {
  if (!el.classList.contains('is-active')) {
    Array.from($pageNavBtn.children).forEach(element => element.classList.remove('is-active'));
    el.classList.add('is-active');
  }
};

const onExpandClick = (e) => {
  const $expandBlock = e.target.closest('.feature-list__content');

  $expandBlock.classList.add('is-open');
  e.target.parentNode.setAttribute('hidden', true);
};

const onExpandSlideClick = (e) => {
  const $expandBlock = e.target.closest('.interfaces__item').querySelector('.js-expand-slide-block');

  $expandBlock.classList.add('is-open');
  e.target.closest('.js-expand-slide-btn').setAttribute('hidden', true);
};

const onExpandSlideClose = (e) => {
  const $expandBlock = e.target.closest('.js-expand-slide-block');

  $expandBlock.classList.remove('is-open');
  $expandBlock.closest('.interfaces__item').querySelector('.js-expand-slide-btn').removeAttribute('hidden');
}

const onPopupEscPress = (e) => {
  if (e.key === ESC) {
    e.preventDefault();
    onPopupClose();
  }
};

const onPopupClick = (e) => {
  e.preventDefault();

  if (e.target.classList.contains('popup__content') || e.target.closest('.js-popup-wrapper')) {
    return;
  } else {
    onPopupClose();
  }
}

const showPopup = (popupStyle) => {
  $popup.classList.add('is-show', popupStyle);
  $bodyEl.classList.add('no-scroll');

  document.addEventListener('keydown', onPopupEscPress);
  $popup.addEventListener('click', onPopupClick);
}

const onVideoClick = () => {
  const video = `
    <div class="promo-video__container">
      <iframe class="promo-video__video" src="https://player.vimeo.com/video/484080006" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
  `;

  $popupWrapper.innerHTML = video;
  showPopup('is-video');
}

const onShowFullsize = (e) => {
  const src = e.target.getAttribute('src');
  const $popupPic = document.createElement('img');

  $popupPic.classList.add('js-popup-img');
  $popupPic.setAttribute('src', src);
  $popupWrapper.appendChild($popupPic);
  showPopup('is-pic');
}

const onPopupClose = () => {
  $popup.classList.remove('is-show');
  $popupWrapper.innerHTML = '';
  $bodyEl.classList.remove('no-scroll');

  document.removeEventListener('keydown', onPopupEscPress);
  $popup.removeEventListener('click', onPopupClick);
}

const pdt360DegViewer = (count, path, keys) => {
  const img = $bodyEl.querySelector('.pdt-viewer__img');
  const hideImg = $bodyEl.querySelector('#dummy');
  const IMG_TYPE = '.png';

  let i = 1; 
  let j = 0;
  let move = [];

  for (let k = 1; k <= count; k++) {
    hideImg.innerHTML += `<img src="${path}${k}${IMG_TYPE}">`
  }

  const moveImg = (e) => {
    let posX = e.clientX || e.touches[0].clientX;
    let coord = (posX - img.offsetLeft);

    e.preventDefault();
    j++;
    move.push(coord);

    let l = move.length;
    let oldMove = move[l - 2];
    let newMove = move[l - 1];

    if (!(j % 3)) {
      if (newMove > oldMove) {
        next(e.target);
      } else if (newMove < oldMove) {
        prev(e.target);
      }
    }
  }

  const clearMove = () => move = [];

  const prev = (e) => {
    if (i <= 1) {
      i = count;
      e.src = `${path}${--i}${IMG_TYPE}`;
      next(e);
    } else {
      e.src = `${path}${--i}${IMG_TYPE}`;
    }
  }

  const next = (e) => {
    if (i >= count) {
      i = 1;
      e.src = `${path}${++i}${IMG_TYPE}`;
      prev(e);
    } else {
      e.src = `${path}${++i}${IMG_TYPE}`;
    }
  }


  img.addEventListener('mousedown', () => {
    img.addEventListener('mousemove', moveImg);
  });

  img.addEventListener('touchmove', moveImg);

  img.addEventListener('mouseleave', clearMove);
  img.addEventListener('mouseup', () => {
    img.removeEventListener('mousemove', moveImg)
  });
  img.addEventListener('touchcancel', clearMove);

  if (keys) {
    window.addEventListener('keydown', (e) => {
      const key = e.key;

      if (key === 37) {
        prev(img);
      } else if (key === 39) {
        next(img);
      }
    });
  }
}

const onThumbGalleryClick = (e) => {
  const $thisGallery = e.currentTarget.closest('.js-gallery');

  if (!e.currentTarget.classList.contains('is-active')) {
    $thisGallery.querySelector('.js-gallery-big').src = e.currentTarget.dataset.src;
    Array.from($thisGallery.querySelectorAll('.gallery__thumb__border')).forEach((item) => item.classList.remove('is-active'));
    e.currentTarget.classList.add('is-active');
    e.target.parentNode.scrollLeft = e.target.offsetLeft;
  }
}

const onBtnGalleryClick = (e) => {
  const $thisGallery = e.currentTarget.closest('.js-gallery');
  const $activeThumb = $thisGallery.querySelector('.gallery__thumb__border.is-active');
  const event = new Event('click');

  if (e.currentTarget.classList.contains('is-right')) {
    $activeThumb.nextElementSibling && $activeThumb.nextElementSibling.dispatchEvent(event);
  } else {
    $activeThumb.previousElementSibling && $activeThumb.previousElementSibling.dispatchEvent(event);
  }
}

$menuBtnDisplayFS.addEventListener('mouseover', () => {
  // $menuBtnDisplayFS.classList.add('add-menu-item-border');
  const subMenu = document.querySelector('#full-screen-dropdown');
  subMenu.classList.add('display-block');
})

$menuBtnDisplayFS.addEventListener('mouseout', () => {
  const subMenu = document.querySelector('#full-screen-dropdown');
  subMenu.classList.remove('display-block');
  // $menuBtnDisplayFS.classList.remove('add-menu-item-border');
})

$fsMenu.addEventListener('mouseout', () => {
  $fsMenu.classList.remove('display-block')
  $menuBtnDisplayFS.classList.remove('add-menu-item-border');
});

$fsMenu.addEventListener('mouseover', () => {
  // $fsMenu.classList.remove('display-block')
  $menuBtnDisplayFS.classList.add('add-menu-item-border');
});

if ($pageNavBtn) {
  $pageNavBtn.addEventListener('click', onNavClick, true);


  document.addEventListener('DOMContentLoaded', () => {
    const handler = () => raf(() => Array.from($pageBlocks).forEach(block => isViewportChecker(block) ? activateNavLink($pageNavBtn.querySelector(`[href="#${block.dataset.section}"]`)) : false));
    
    handler();
    window.addEventListener('scroll', handler);
  });
}

if ($viewerLoader) {
  window.addEventListener('load', () => {
    $viewerLoader.classList.add('is-hidden');
  });

  pdt360DegViewer(25, '/images/car-model-3d/', true);
}

if ($contactsMap) {
  const map = L.map('contactsMap').setView([coord.lat, coord.lng], 13);
  const marker = L.marker([coord.lat, coord.lng]).addTo(map);

  L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    maxZoom: 18,
    id: 'mapbox/dark-v10',
    tileSize: 512,
    zoomOffset: -1,
    accessToken: ACCESS_TOKEN
  }).addTo(map);
}

if ($preorderForm) {
  $preorderForm.addEventListener('submit', () => {
    $preorderForm.querySelector('.preorder__message').classList.add('is-show');
  });
}

if ($dealerForm) {
  const fields = Array.from($dealerForm.querySelectorAll('input'));
  
  $dealerForm.querySelector('[data-region-id]').addEventListener('change', () => {
    $dealerForm.querySelector('#countries').removeAttribute('disabled')
  });

  fields.forEach(item => item.addEventListener('input', (e) => {
    e.target.classList.remove('invalid')
  }))

  fields.forEach(item => item.addEventListener('invalid', (e) => {
    e.target.classList.add('invalid')
  }))

  $dealerForm.addEventListener('submit', () => {
    fields.forEach(item => item.classList.remove('invalid'))
  })
}

if ($contactsForm) {
  $contactsForm.addEventListener('submit', () => {
    $contactsForm.querySelector('.form-message').classList.add('is-show');
    $contactsForm.querySelector('.js-submit-btn').setAttribute('hidden', true);
  });
}

if ($popup) {
  const $popupClose = $popup.querySelector('.js-popup-close');

  $popupClose.addEventListener('click', onPopupClose);
}

if ($fullsizeImg) {
  Array.from($fullsizeImg).forEach(btn => btn.addEventListener('click', onShowFullsize));
}

if ($videoPopupBtn.length) {
  Array.from($videoPopupBtn).forEach(btn => btn.addEventListener('click', onVideoClick));
  // $videoPopupBtn.addEventListener('click', onVideoClick);
}

if ($gallery.length) {
  const $galleryThumb = $bodyEl.querySelectorAll('.gallery__thumb__border');
  const $galleryBtn = $bodyEl.querySelectorAll('.js-gallery-btn');

  Array.from($galleryThumb).forEach(thumb => thumb.addEventListener('click', onThumbGalleryClick));
  Array.from($galleryBtn).forEach(btn => btn.addEventListener('click', onBtnGalleryClick, true));
}

$burgerBtn.addEventListener('click', onMenuBtnClick);
$header.addEventListener('keydown', onMenuEscPressed);



$btnSwitchGalleryExt.addEventListener('click', () => {
  $btnSwitchGalleryExt.classList.add('active');
  $btnSwitchGalleryInt.classList.remove('active');
  document.querySelector('#gallery-exterior').classList.add('visible');
  document.querySelector('#gallery-exterior').classList.remove('hide');
  document.querySelector('#gallery-interior').classList.remove('visible');
  document.querySelector('#gallery-interior').classList.add('hide');
} );
$btnSwitchGalleryInt.addEventListener('click', () => {
  $btnSwitchGalleryExt.classList.remove('active');
  $btnSwitchGalleryInt.classList.add('active');
  document.querySelector('#gallery-interior').classList.add('visible');
  document.querySelector('#gallery-interior').classList.remove('hide');
  document.querySelector('#gallery-exterior').classList.remove('visible');
  document.querySelector('#gallery-exterior').classList.add('hide');
} );



Array.from($expandTextBtn).forEach(btn => btn.addEventListener('click', onExpandClick));
Array.from($expandSlideBtn).forEach(btn => btn.addEventListener('click', onExpandSlideClick));
Array.from($expandSlideClose).forEach(btn => btn.addEventListener('click', onExpandSlideClose));

const $btnShowMore = document.querySelectorAll('.btn-show-more');

if ($btnShowMore.length) {

  const $closeLabel = "<svg><use xlink:href='#i-minus'></use></svg> Свернуть список";
  const $openLabel = "<svg><use xlink:href='#i-plus'></use></svg> Раскрыть список";

  Array.from($btnShowMore).forEach(btn => btn.addEventListener('click', (e) => {
    const $targetId = e.target.parentNode.querySelector('.block-show-more').id;

    if( e.target.classList.contains('open') ) {
      e.target.parentNode.querySelector('.block-show-more').classList.remove('open');
      e.target.classList.remove('open');
      e.target.classList.remove('mt-xl-24');

      e.target.innerHTML = $openLabel;
      console.log($targetId);
      window.location.hash = $targetId;
    } else {
      e.target.parentNode.querySelector('.block-show-more').classList.add('open');
      e.target.classList.add('open');
      e.target.classList.add('mt-xl-24');
      e.target.innerHTML = $closeLabel;
    }

  }, true));
}